<template>
  <div class="verify">
    <Nav navType="fun_text" :textCon="pageTitle">
      <template v-slot:rightText>
        <span
          class="iconfont icongengduo"
          style="color:#000;font-size:24px"
        ></span>
      </template>
    </Nav>
    <div class="title_text">{{title}}</div>
    <!-- 绑定新手机号码 -->
    <div class="phone_number" v-if="type=='phoneNumber'">
      <Field v-model="inputNumber" placeholder="请输入您的手机号码并点击获取验证码" :clearable="true" class="input_line" type="tel"/>
      <div class="ver_code">
        <Field v-model="inputVerCode" :clearable="true" class="input_line input_ver_code" type="number"/>
        <div class="button ver_button" @click="getVerCode" :class="{'gray_button':second}">
          {{buttonName}}<span v-if="second">({{second}})</span>
        </div>
      </div>
      <div class="button submit" @click="submit(0)">确认</div>
    </div>
    <!-- 验证原先手机号码 -->
    <div class="phone_number" v-if="type=='oldPhoneNumber'">
      <div class="ver_code">
        <Field v-model="inputVerCode" :clearable="true" class="input_line input_ver_code" type="number"/>
        <div class="button ver_button" @click="getVerCode" :class="{'gray_button':second}">
          {{buttonName}}<span v-if="second">({{second}})</span>
        </div>
      </div>
      <div class="right_button"><span>原先手机号不能用</span></div>
      <div class="button submit" @click="submit(0)">确认</div>
    </div>
    <!-- 设置登录密码 -->
    <div class="password" v-if="type=='password'">
      <Field v-model="inputPassword" placeholder="8-20位数字/字母/字符组合" class="input_line" type="password"/>
      <Field v-model="inputPassword2" placeholder="请再次确认输入" class="input_line" type="password"/>
      <div class="button submit" @click="submit(1)">确认</div>
    </div>
    <!-- 验证旧密码 -->
    <div class="password" v-if="type=='old_password'">
      <Field v-model="oldPassword" placeholder="" class="input_line" type="password"/>
      <div class="right_button"><span>忘记登陆密码</span></div>
      <div class="button submit" @click="verifyOldPassword()">确认</div>
    </div>
    <!-- 设置交易密码 -->
    <div class="dealPassword" v-if="type == 'dealPassword'">
      <!-- 密码输入框 -->
      <PasswordInput
        :value="inputDealPassword"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
        gutter="14px"
      />
      <div class="button" style="margin-top:30px" @click="setPayPassword()">确认</div>
      <!-- 数字键盘 -->
      <NumberKeyboard
        :show="showKeyboard"
        @input="onInput"
        @delete="onDelete"
        @blur="showKeyboard = false"
      />
    </div>
    <!-- 验证交易密码 -->
    <div class="dealPassword" v-if="type == 'oldDealPassword'">
      <!-- 密码输入框 -->
      <PasswordInput
        :value="inputDealPassword"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
        gutter="14px"
        style="margin-bottom:22px;"
      />
      <div class="right_button">
        <router-link to="/authentication">
          <span>忘记交易密码</span>
        </router-link>
      </div>
      <div class="button" style="margin-top:30px" @click="verOldPayPassword()">下一步</div>
      <!-- 数字键盘 -->
      <NumberKeyboard
        :show="showKeyboard"
        @input="onInput"
        @delete="onDelete"
        @blur="showKeyboard = false"
      />
    </div>
    <!-- 成功弹窗 -->
    <Popup v-model="show_success" class="success" :close-on-click-overlay="false">
      <div class="image">
        <span class="iconfont iconduigou" ></span>
      </div>
      <div class="success_text">{{successMsg[sindex].title}}</div>
      <div class="success_info">{{successMsg[sindex].line1}}</div>
      <div class="success_info">{{successMsg[sindex].line2}}</div>
      <div class="success_button" @click="closePage">我知道了</div>
    </Popup>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import { Field, Popup, PasswordInput, NumberKeyboard } from "vant" 
import Setting from "@/api/setting"
export default {
  components: {
    Nav,
    Field,
    Popup,
    PasswordInput, 
    NumberKeyboard 
  },
  data() {
    return {
      type:"",
      pageTitle:"", //页面顶部title
      title:"", //内容第一行title
      inputNumber: "", //用户输入的手机号
      inputVerCode:"", //输入的验证码
      inputPassword: "", //输入的密码
      inputPassword2: "", //确认密码
      oldPassword:"", //旧登陆密码
      inputDealPassword: "", //输入的交易密码
      showKeyboard: true,
      buttonName: "获取验证码",
      second:"",
      show_success:false,
      successMsg:[
        {title:"绑定成功",line1:"您的手机号码已绑定成功",line2:"请妥善保管，定期更新"},
        {title:"设置成功",line1:"您的密保问题已设置成功",line2:"请妥善保管，定期更新"},
        {title:"设置成功",line1:"您的登录密码已设置成功",line2:"请妥善保管，定期更新"},
        {title:"设置成功",line1:"您的支付密码已设置成功",line2:"请妥善保管，定期更新"},
      ],
      sindex:0,
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){
      this.type = this.$route.query.type;
      if(this.type == "phoneNumber"){
        this.pageTitle = "绑定手机号码"
        this.title = "绑定您的手机号码"
      }else if(this.type == "password"){
        this.pageTitle = "设置登录密码"
        this.title = "设置您的登陆密码"
      }else if(this.type == "old_password"){
        this.pageTitle = "验证原先登陆密码"
        this.title = "请输入原先登陆密码"
      }else if(this.type == "dealPassword"){
        this.pageTitle = "设置交易密码"
        this.title = "设置您的6位数交易密码"
      }else if(this.type == "oldPhoneNumber"){
        var mobile = '153****0900'
        this.pageTitle = "验证原先手机号码"
        this.title = "请输入原先手机号码" + mobile + "收到的短信验证"
      }else if(this.type == "oldDealPassword"){
        this.pageTitle = "验证原先交易密码"
        this.title = "请输入原先交易密码"
      }
    },
    getVerCode(){ //获取验证码
      this.buttonName = "重新获取"
      this.second = 5
      this.getSecond()
    },
    getSecond(){
      setTimeout(() => {
        this.second --
        if(this.second > 0){
          this.getSecond()
        }
      }, 1000);
    },
    submit(i){
      if(this.type == 'password'){
        if(this.inputPassword != this.inputPassword2){
          this.$toast('两次输入的密码不一致!')
        }else{
          var value = this.inputPassword2
          this.editPassword(value,i)
        }
      }
    },
    // 判断登陆密码是否正确
    async verifyOldPassword() {
      try {
        const res = await Setting.verifyPass(this.oldPassword);
        if (res.code == 200) {
          this.type = 'password';
          this.pageTitle = "设置新的登录密码"
          this.title = "设置您的新登陆密码";
        }
        this.$toast(res.msg);
      } catch(err) {
        console.log(err);
      }
    },
    //修改 登陆密码
    async editPassword(value,i) {
      try {
        const res = await Setting.EditPassword(value);
        if (res.code == 200) {
          this.sindex = i;
          this.show_success = true;
        }
        this.$toast(res.msg);
      } catch(err) {
        console.log(err);
      }
    },
    //修改 支付密码密码
    async setPayPassword() {
      var value = this.inputDealPassword
      try {
        const res = await Setting.EditPayPassword(value);
        if (res.code == 200) {
          this.sindex = 3;
          this.show_success = true;
        }
        this.$toast(res.msg);
      } catch(err) {
        console.log(err);
      }
    },
    // 验证旧的支付密码
    async verOldPayPassword() {
      var value = this.inputDealPassword
      try {
        const res = await Setting.verifyPayPass(value);
        if (res.code == 200) {
          console.log(res.msg)
          this.inputDealPassword = ""
          this.$toast('验证成功')
          this.type = 'dealPassword';
          this.pageTitle = "设置新的交易密码"
          this.title = "设置您的新交易密码"
        }
        this.$toast(res.msg);
      } catch(err) {
        console.log(err);
      }
    },
    closePage(){
      this.show_success = false;
      this.goLocalStorageUrl();
    },
    onInput(key) {
      this.inputDealPassword = (this.inputDealPassword + key).slice(0, 6);
    },
    onDelete() {
      this.inputDealPassword = this.inputDealPassword.slice(0, this.inputDealPassword.length - 1);
    }
  },

};
</script>
<style>
.van-uploader__wrapper{
  height: 100%
}
.van-password-input{
  margin: 0;
}
</style>
<style lang="scss" scoped>
.verify {
  width: 100%;
  min-height: 100%;
  padding: 50px 12px 1px 12px;
  background-color: fff;
  font-family: PingFang SC;
  background-color: #f4f4f4;
  font-weight: 500;
  position: relative;
  .title_text {
    height: 63px;
    line-height: 63px;
    font-size: 15px;
    color: #333;
  }
  .button{
    background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    border-radius: 5px;
    text-align: center;
    color:#fff;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
  }
  .right_button{
    width:100%;
    text-align: right;
    color:#ff8000;
    font-size: 12px;
    line-height: 16px;
    a {
      color: #ff8000;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .submit{
    margin-top: 18px;
  }
  .input_line{
    height: 45px;
    border-radius: 5px;
    margin-bottom: 12px;
  }
  .phone_number{
    .ver_code{
      display: flex;
      .input_ver_code{
        flex:1;
      }
      .ver_button{
        width:130px;
        margin-left: 11px;
      }
      .gray_button{
        background: #ccc;
      }
    }
  }
  .success{
    width:66%;
    padding:0 13px;
    text-align: center;
    .image{
      margin: 0 auto;
      margin-top: 35px;
      width:84px;
      height: 84px;
      border:1px solid #eee;
      line-height: 84px;

      .iconduigou {
        color: rgb(45, 168, 14);
        font-size: 48px;
      }
    }
    .success_text{
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .success_info{
      font-size: 13px;
      line-height: 17px;
    }
    .success_button{
      margin:14px auto 24px auto;
      width:138px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-size: 13px;
      color:#fff;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
  }
}
</style>
